import React, { useState } from "react";
import axios from "axios";
import data from "../services/config";
import "./../assets/upload.css";

const { apiurl } = data;

const UploadImages = ({ onImageUploadStart, onImageUploaded }) => {
  const [files, setFiles] = useState([]); // Initialize files with an empty array
  const [previewImages, setPreviewImages] = useState([]); // Initialize an array for previews

  const saveFiles = (e) => {
    const newFiles = Array.from(e.target.files); // Convert FileList to Array
    const allFiles = [...files, ...newFiles]; // Combine old and new files

    setFiles(allFiles); // Update the state
    showPreview(allFiles); // Generate new preview
  };

  const showPreview = (allFiles) => {
    let previews = [];
    if (allFiles.length === 0) {
      setPreviewImages([]);
      return;
    }

    allFiles.forEach((file, index) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        previews.push(reader.result);
        if (previews.length === allFiles.length) {
          setPreviewImages(previews);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleImageRemove = (indexToRemove) => {
    const updatedFiles = files.filter((_, index) => index !== indexToRemove);
    setFiles(updatedFiles);
    showPreview(updatedFiles);
  };

  const uploadFiles = async () => {
    if (!files.length) return; // Check if files are selected

    onImageUploadStart();

    const formData = new FormData();
    for (let file of files) {
      // Loop through the files and append them
      formData.append("files[]", file); // Use "files[]" as the key
    }

    try {
      const { data } = await axios.post(`${apiurl}/uploadImages`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      onImageUploaded(data.uploadedImages);
    } catch (ex) {
      console.log(ex);
    }
  };

  return (
    <div className="App">
      <div className="file-upload-wrapper">
        <input
          type="file"
          id="file-upload"
          multiple
          accept="image/*"
          onChange={saveFiles}
        />
        <label htmlFor="file-upload" className="file-upload-label">
          Choose Files
        </label>
      </div>

      <div className="image-preview-wrapper">
        {previewImages.map((src, index) => (
          <div key={index} className="preview-img">
            <img src={src} alt="preview" width="100" />
            <button onClick={() => handleImageRemove(index)}>Remove</button>
          </div>
        ))}
      </div>

      <div className="btn-upload">
        <button disabled={!files.length} onClick={uploadFiles}>
          Upload
        </button>
      </div>
    </div>
  );
};

export default UploadImages;
