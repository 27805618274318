// components/Login.js
import React, { useState } from "react";
import "./Login.css";
import http from "../services/http";
import data from "../services/config";
import logo from "./../logo.png";
import { toast } from "react-toastify";

const { loginSite } = data;

const Login = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [Working, setWorking] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setWorking(true);
    const authUrl = `${loginSite}/wp-json/jwt-auth/v1/token`;
    const payload = {
      username,
      password,
    };

    try {
      const { data } = await http.post(authUrl, payload);
      setWorking(false);
      if (data.token) {
        onLoginSuccess(data);
      }
      // console.log(data);

      // Handle the successful authentication as needed
      // For example, save the user details, set logged-in status, redirect, etc.
    } catch (error) {
      setWorking(false);
      // Handle the error, for example, show an error message if authentication fails
      // console.error("Authentication failed:", error);
      const msg = error.response.data.code;
      toast.error("Error Login" + msg);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="loginForm">
      <div className="pv-logo">
        <img src={logo} alt="PakVariety Logo" />
      </div>
      <label>
        Username:
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </label>
      <label>
        Password:
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </label>
      <button type="submit">Login</button>
      {Working && <p>Please wait</p>}
    </form>
  );
};

export default Login;
