import React from "react";

const ProductPreview = ({ productData, onCreateProduct, onRegenerate }) => {
  const {
    images,
    title,
    description,
    short_description,
    categories,
    keywords,
    price,
  } = productData;

  return (
    <div className="product-preview">
      <div className="product-image">
        <img src={images[0].src} alt={title} />
      </div>
      <div className="product-details">
        <h2>{title}</h2>
        <h3>Rs. {price}</h3>
        <p dangerouslySetInnerHTML={{ __html: description }}>{}</p>
        <h3>Short Description</h3>
        <div
          className="short-description"
          dangerouslySetInnerHTML={{ __html: short_description }}
        />
        <div className="categories">
          <strong>Categories:</strong>
          <ul>
            {categories.map((category) => (
              <li key={category.value}>{category.label}</li>
            ))}
          </ul>
        </div>
        <div className="tags">
          <strong>Tags:</strong>
          <p>{keywords}</p>
        </div>
      </div>
      <button onClick={onCreateProduct}>Create in PakVariety</button>
      <button onClick={onRegenerate}>Regenrate </button>
    </div>
  );
};

export default ProductPreview;
