const data = {
  apiurl: "https://7dcldl3qo2.execute-api.us-east-1.amazonaws.com/dev/api",
  // apiurl: "http://ec2-3-238-186-49.compute-1.amazonaws.com:8080/api",
  // apiurlFunction: "http://ec2-3-238-186-49.compute-1.amazonaws.com:8080/api",
  apiurlFunction:
    "https://lpvby5y4xzvky4ysubzobfuthi0jxpyh.lambda-url.us-east-1.on.aws/api",
  siteApiURL: "https://pakvariety.com/wp-json/pv/v1",
  loginSite: "https://pakvariety.com",
  tokensLimit: 50000,
  productFeatures: [
    "COD Payment",
    "Fast Delivery",
    "Washable",
    "Winter Collection",
    "Summer Collection",
    "New Arrial",
    "Outfit",
    "Party Dress",
    "Fancy Dress",
  ],
};

export default data;
