import React, { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";
import { getCurrentUser } from "./../services/auth";
import "./../assets/Layout.css";

const Layout = ({ onLogout }) => {
  const [Username, setUsername] = useState("");
  useEffect(() => {
    const current_user = getCurrentUser();
    if (current_user) {
      setUsername(current_user.user_display_name);
    }
  }, []);

  const handleLogoutClick = (e) => {
    e.preventDefault();
    onLogout(); // Call the passed-in onLogout function
    // Redirect or navigate to login or another page if necessary
  };

  return (
    <div className="layout-container">
      <nav className="nav-bar">
        <ul className="nav-list">
          <li className="nav-item">
            <Link to="/home" className="nav-link">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/scrap" className="nav-link">
              Scrap
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/products" className="nav-link">
              Products
            </Link>
          </li>
          <li className="nav-item">
            <a href="/logout" className="nav-link" onClick={handleLogoutClick}>
              Logout
            </a>
          </li>
          <li className="nav-item nav-item-name">Hi, {Username}</li>
        </ul>
      </nav>

      <Outlet />
    </div>
  );
};

export default Layout;
