import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import ProductScraper from "./components/ScrapProduct";
import Layout from "./components/Layout";
import UploadProduct from "./components/UploadProduct";
import Login from "./pages/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useLocalStorage from "./services/useLocalStorage";
import Home from "./pages/Home";
import VendorProducts from "./pages/VendorProducts";

const App = () => {
  const [User, setUser] = useLocalStorage("pv_user", null);

  // Function to check authentication
  const isAuthenticated = () => {
    // return true;
    // Replace with your logic to check if the user is authenticated
    return User !== null;
  };

  // Higher-order component to protect routes
  const ProtectedRoute = ({ element }) => {
    return isAuthenticated() ? element : <Navigate to="/login" />;
  };

  const handleLogin = (user_info) => {
    setUser(user_info);
    window.location = "/home";
  };

  const handleLogout = () => {
    setUser(null);
    window.location = "/login";
  };
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login onLoginSuccess={handleLogin} />} />{" "}
        {/* Login with different layout */}
        <Route path="/" element={<Layout onLogout={handleLogout} />}>
          {/* All routes inside here are protected */}
          <Route path="/home" element={<ProtectedRoute element={<Home />} />} />
          <Route
            path="/scrap"
            element={<ProtectedRoute element={<ProductScraper />} />}
          />
          <Route
            path="/products"
            element={<ProtectedRoute element={<VendorProducts />} />}
          />
          <Route
            path="/new"
            element={<ProtectedRoute element={<UploadProduct />} />}
          />
        </Route>
      </Routes>
      <ToastContainer />
    </>
  );
};

export default App;
