import React, { useState, useEffect } from "react";
import { FacebookShareButton, WhatsappShareButton } from "react-share";
import {
  FaExternalLinkAlt,
  FaFacebook,
  FaLink,
  FaWhatsapp,
} from "react-icons/fa";
import "./VendorProducts.css"; // Import the CSS file
import http from "../services/http";
import { getCurrentUser } from "../services/auth";
import { toast } from "react-toastify";
import data from "../services/config";
import { format_price } from "../services/helper";
import { Link } from "react-router-dom";

const { apiurl } = data;
const VendorProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const current_user = getCurrentUser();
    const getVendorProducts = async () => {
      try {
        setLoading(true); // set loading to true when fetching data
        const response = await http.get(
          `${apiurl}/vendor-products?product_ids=${current_user.product_ids}`
        );
        const products = response.data;
        setProducts(products);
        setLoading(false); // set loading to false after fetching data
      } catch (error) {
        setLoading(false); // also set loading to false if an error occurs
        toast.error("Error while fetching token", error.message);
      }
    };
    getVendorProducts();
  }, []);

  const shareOnFacebook = (product) => {
    // Implement your Facebook sharing logic here
  };

  const shareOnInstagram = (product) => {
    // Implement your Instagram sharing logic here
  };

  return (
    <div className="container">
      {loading ? (
        <div className="loading-icon">Loading...</div> // Replace this with your actual loading icon
      ) : (
        <>
          <p>
            <Link className="button-link" to="/new">
              Add New Product
            </Link>
          </p>
          <div className="products-grid">
            {products.map((product) => (
              <div key={product.id} className="product-card">
                <a href={product.url} target="_blank" rel="noopener noreferrer">
                  <img
                    src={product.image}
                    alt={product.name}
                    className="product-image"
                  />
                </a>
                <p>{product.name}</p>
                <p>{format_price(product.price)}</p>
                <div className="button-container">
                  <Link
                    style={{ textDecoration: "none", color: "#000" }}
                    to={product.url}
                    target="_blank"
                  >
                    <FaExternalLinkAlt />
                  </Link>
                  <FacebookShareButton url={product.url} quote={product.name}>
                    <FaFacebook />
                  </FacebookShareButton>
                  <WhatsappShareButton url={product.url} title={product.name}>
                    <FaWhatsapp />
                  </WhatsappShareButton>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default VendorProducts;
