import { useEffect, useState } from "react";
import { getTokensUage } from "../services/auth";

function Home() {
  const [TokensIno, setTokensIno] = useState({});

  useEffect(() => {
    const tokens_info = getTokensUage();
    console.log(tokens_info);
    setTokensIno(tokens_info);
  }, []);

  return (
    <div>
      <h1>Token Usage</h1>
      <div>Input Tokens: {TokensIno.prompt_tokens}</div>
      <div>Output Tokens:{TokensIno.completion_tokens}</div>
      <div>Total Tokens: {TokensIno.total_tokens}</div>
    </div>
  );
}

export default Home;
