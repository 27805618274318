import React, { useState, useEffect } from "react";

function QualityCheck({ RefinedInfo, onQualityFailed, onQualityPassed }) {
  const [prompt, setPrompt] = useState("");

  useEffect(() => {
    let errors = "";

    const { title, description, short_description, images, keywords } =
      RefinedInfo;

    // Check title length
    if (title.length < 50 || title.length > 65) {
      errors += `Change following title must be between 50-60 characters.\n "${title}"\n`;
    }

    // Check description length
    const descriptionWords = description.split(" ").length;
    if (descriptionWords < 130) {
      errors += `Change following description must have min 150 words also must have an internal anchor with title attribute link which may be link to shop https://pakvariety.com/shop.\n "${description}"\n`;
      errors += `And following short_descrition inside description can also be appended \n "${short_description}" \n`;
    }

    // Check short_description HTML content
    if (
      !short_description.includes("<ul>") ||
      !short_description.includes("<li>")
    ) {
      errors += `Current short_description: ${short_description},\n it must contain data in bullet points (HTML list format).\n`;
    }

    // Check keywords comma-separated
    if (!keywords.includes(",")) {
      errors += `Current keywrods: ${keywords} must be comma-separated.\n`;
    }

    // Check images with keys url and alt
    // images.forEach((image, index) => {
    //   if (!image.url || !image.alt) {
    //     errors += `images from  at index ${index} must contain 'url' and 'alt' keys.\n`;
    //   }
    // });

    // Update the prompt if there are any errors
    if (errors) {
      setPrompt(errors);
    }
  }, [RefinedInfo, prompt]);

  return (
    <div>
      {prompt && (
        <div>
          <h3>Quality Check Failed:</h3>
          <pre>{prompt}</pre>
          <button onClick={(e) => onQualityFailed(e, prompt)}>
            Refine Again
          </button>
        </div>
      )}
      {!prompt && (
        <button onClick={onQualityPassed}>Quality Passed, Next</button>
      )}
    </div>
  );
}

export default QualityCheck;
