import axios from "axios";

// New axio interceptor syntax from
// source: https://stackoverflow.com/questions/68714143/how-can-i-use-axios-interceptors-to-add-some-headers-to-responses
// axios.interceptors.request.use(
//   (config) => {
//     console.log(config.url);

//     if (
//       config.url !==
//       "https://7dcldl3qo2.execute-api.us-east-1.amazonaws.com/dev/api/uploadImages"
//     )
//       config.headers["Content-Type"] = "application/json";

//     return config;
//   },
//   (error) => {
//     Promise.reject(error);
//   }
// );

// axios.interceptors.response.use(
//   (response) => {
//     // Handle successful response
//     return response;
//   },
//   (error) => {
//     // Handle error
//     // console.log(error);
//     const expectedErrors =
//       error.response &&
//       error.response.status >= 400 &&
//       error.response.status < 500;

//     if (!expectedErrors) {
//       // alert("An unexpected error occurred!");
//     }
//     return Promise.reject(error);
//   }
// );

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
